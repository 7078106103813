import styles from "./assets/scss/boarding.module.scss";
import CustomCard from "components/mui/card/card";
import { Button } from "components/mui/button";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import { IProps } from "./types/boarding";

const Boarding = ({
  children,
  buttonLabel = "Signup",
  paragraphContent = "Already have an account?",
  onButtonClick,
  cardLabel = "Seconds to Login!",
  cardLabelStyle = { fontSize: "30px", fontWeight: 600 },
}: IProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleDefaultClick = () => {
    navigate("../signup/");
  };

  return (
    <div
      className={styles.page}
      style={{
        backgroundColor: theme.custom.background.main,
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <div className={styles.timer1}></div>
      <div className={styles.checklist1}></div>
      <div className={styles.timer2}></div>
      <div className={styles.checklist2}></div>
      <div className={styles.backgroundContainer}>
        <div className={`${styles.tri} ${styles.tri1}`}></div>
        <div className={`${styles.tri} ${styles.tri2}`}></div>
        <div className={`${styles.tri} ${styles.tri3}`}></div>
        <div className={`${styles.tri} ${styles.tri4}`}></div>
      </div>
      <div style={{ position: "fixed", top: "0px", width: "100vw" }}>
        <header className={styles.header}>
          <a href="../">
            <img
              src={require("./assets/imgs/logo.png")}
              alt="Logo"
              className={styles.logo}
            />
          </a>
          <div className={styles["right-side"]}>
            <p style={{ flex: 8, fontWeight: "600", fontSize: "16px" }}>
              {paragraphContent}
            </p>
            <Button
              label={buttonLabel}
              sx={{
                color: theme.custom.neutralColors.color11,
                backgroundColor: theme.palette.secondary.dark,
                flex: 4,
                borderRadius: "20px",
              }}
              onClick={onButtonClick || handleDefaultClick}
            />
          </div>
        </header>
        <div className={styles.content}>
          <CustomCard
            className={styles.card}
            label={<span style={cardLabelStyle}>{cardLabel}</span>}
          >
            {children}
          </CustomCard>
        </div>
      </div>
    </div>
  );
};

export default Boarding;
