import React from "react";
import {
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import styles from "./assets/scss/header.module.scss";
import { Button } from "components/mui/button";

import searchicon from "./assets/imgs/search.svg";
import logo from "./assets/imgs/Frame 427319045.png";
import clock from "./assets/imgs/fluent_timer-28-regular.svg";
import alarm from "./assets/imgs/alarm.svg";
import setting from "./assets/imgs/uiw_setting-o.svg";
import chat from "./assets/imgs/qlementine-icons_faq-16.svg";
import { useSelector } from "react-redux";

const Header = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const app = useSelector((state: any) => state.app?.allData || {});

  const handleCreate = () => {
    console.log("Create Tracker clicked");
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.custom.neutralColors.color11 }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {/* Logo on the left */}
        <a href="../">
          <img
            src={logo}
            alt="Logo"
            style={{
              width: isSmallScreen ? 120 : 150,
              height: 40,
            }}
          />
        </a>

        {/* Centered Button and TextField */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            margin: isSmallScreen ? "10px 0" : "0",
          }}
        >
          <Button
            label="Create Tracker"
            className={styles.searchButton}
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.light,
            }}
            onClick={handleCreate}
          />
          <TextField
            placeholder="Search..."
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchicon} alt="Search Icon" />
                </InputAdornment>
              ),
            }}
            sx={{
              width: isSmallScreen ? "80%" : "25vw",
              marginLeft: "10px",
              "& .MuiOutlinedInput-root": {
                fontFamily: "'Cairo', sans-serif",
              },
              "& .MuiOutlinedInput-input": {
                fontFamily: "'Cairo', sans-serif",
                fontSize: "1rem",
                padding: "7px",
              },
              "& .MuiOutlinedInput-input:-webkit-autofill": {
                "-webkit-box-shadow": "0 0 0 100px transparent inset",
                "-webkit-text-fill-color": "inherit",
                "caret-color": "inherit",
              },
            }}
          />
        </div>

        {/* Icons on the right */}
        <div
          className={styles.headerRight}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <span className={styles.icon}>
            <img src={clock} alt="Clock" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={alarm} alt="Alarm" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={setting} alt="Settings" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={chat} alt="Chat" width="20" height="20" />
          </span>
          <span
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.custom.neutralColors.color11,
            }}
            className={styles.circle}
          >
            {`${app.first_name[0]}${app.last_name[0]}`}
          </span>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
