import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

interface CustomSelectProps {
  value: string | number | undefined;
  onChange: (event: SelectChangeEvent<string | number>) => void;
  options: { label: string; value: string | number }[];
  label?: any;
  displayEmpty?: boolean;
  disabled?: boolean;
  className?: string;
  fontFamily?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  value,
  onChange,
  options,
  label,
  displayEmpty = false,
  disabled = false,
  className,
  fontFamily = "'Cairo', sans-serif",
}) => {
  return (
    <FormControl
      fullWidth
      disabled={disabled}
      className={className}
      style={{ fontFamily }}
    >
      {label && (
        <InputLabel
          style={{
            fontFamily,
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        style={{
          marginBottom: "10px",
          fontFamily,
        }}
        value={value}
        onChange={onChange}
        displayEmpty={displayEmpty}
        label={label}
        sx={{
          "& .MuiSelect-select": {
            fontFamily,
            textAlign: "left",
            direction: "ltr",
          },
        }}
      >
        {displayEmpty && (
          <MenuItem value="" disabled style={{ fontFamily }}>
            {label || "Select an option"}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ fontFamily, textAlign: "left", direction: "ltr" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
