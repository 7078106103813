import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUIState } from "store/interface/stateInterfaces";

const initState: IUIState = {
  theme: "light",
  loading: false,
  notification: {
    level: "info",
    message: "",
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initState,
  reducers: {
    updateNotification(state, action) {
      state.notification = action.payload;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    toggleColors: (state: IUIState) => {
      state.theme = state.theme === "light" ? "dark" : "light";
    },
    changeColors: (state: IUIState, action: PayloadAction<boolean>) => {
      state.theme = action.payload ? "dark" : "light";
    },
  },
});

export const { toggleColors, changeColors, setLoading, updateNotification } =
  uiSlice.actions;

export default uiSlice.reducer;
