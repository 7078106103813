import Button from "@mui/material/Button";
import { IPropsWithStyle } from "./types/button";

const ButtonComponent = ({
  label,
  onClick,
  disabled = false,
  className,
  ...otherProps
}: IPropsWithStyle) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className={className}
      {...otherProps}
    >
      {label}
    </Button>
  );
};

export default ButtonComponent;
