import React, { useState, useEffect } from "react";
import styles from "./assets/scss/signup.module.scss";
import CustomTextField from "components/mui/text-field/text-field";
import CustomPasswordField from "components/mui/pass-text-field/pass-text-field";
import { Button } from "components/mui/button";
import axios from "axios";
import CustomSelect from "components/mui/menu/menu";
import { SelectChangeEvent, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Icons
import passicon from "./assets/imgs/Group.svg";
import emailicon from "./assets/imgs/mage_email-notification.svg";
import nameicon from "./assets/imgs/fluent_person-28-regular.svg";
import mobicon from "./assets/imgs/Vector.svg";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui/ui-slice";
import { Boarding } from "layouts/boarding";
import { UserType } from "./enum/signup";
import { ISignUp } from "./types/SignUp";

const SignUp = () => {
  const theme = useTheme();

  const [formData, setFormData] = useState<ISignUp>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    userType: "",
    subscriptionType: "",
    password: "",
    entityName: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<ISignUp>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    userType: "",
    subscriptionType: "",
    entityName: "",
  });

  const [subscriptionOptions, setSubscriptionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [accountTypes, setAccountTypes] = useState<
    { label: string; value: string }[]
  >([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiURL}/api/subscriptions/`);
        const data = response.data;

        const subscriptionOptions = data.tiers.map((tier: string) => ({
          label: tier,
          value: tier,
        }));

        const accountTypes = data.account_types.map((type: string) => ({
          label: type,
          value: type.toUpperCase(),
        }));

        setSubscriptionOptions(subscriptionOptions);
        setAccountTypes(accountTypes);
      } catch (error) {
        console.error("Failed to fetch subscription data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      subscriptionType: "",
    }));
  }, [formData.userType]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (
    event: SelectChangeEvent<string | number>,
    field: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: event.target.value,
    }));
  };

  const apiURL = process.env.REACT_APP_API_URL || "";

  const validateForm = () => {
    let valid = true;
    let validationErrors = { ...errors };

    if (!formData.firstName) {
      validationErrors.firstName = "First Name is required";
      valid = false;
    }

    if (!formData.lastName) {
      validationErrors.lastName = "Last Name is required";
      valid = false;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email || !emailRegex.test(formData.email)) {
      validationErrors.email = "Invalid email address";
      valid = false;
    }

    const phoneRegex = /^\+?[0-9]{10,15}$/;
    if (!formData.phone || !phoneRegex.test(formData.phone)) {
      validationErrors.phone = "Invalid phone number";
      valid = false;
    }

    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    if (!formData.password) {
      validationErrors.password = "Password is required";
      valid = false;
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters";
      valid = false;
    } else if (!passwordRegex.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character";
      valid = false;
    }

    if (!formData.userType) {
      validationErrors.userType = "User Type is required";
      valid = false;
    }

    if (!formData.subscriptionType) {
      validationErrors.subscriptionType = "Subscription Type is required";
      valid = false;
    }

    setErrors(validationErrors);
    return valid;
  };

  const handleSignUp = async () => {
    if (!validateForm()) return;

    setIsSubmitting(true);

    try {
      const payload = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        userType: formData.userType,
        subscriptionType: formData.subscriptionType,
        password: formData.password,
        entityName: formData.entityName,
      };

      const response = await axios.post(`${apiURL}/register/`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Sign Up Successful:", response.data);
      dispatch(
        uiActions.updateNotification({
          message: response.data.message || "Signup successfully",
          level: "success",
        })
      );
      navigate("../");
    } catch (error: any) {
      if (error.response) {
        const message = error.response.data.message || "Unknown error";
        console.error("API Error:", message);

        dispatch(
          uiActions.updateNotification({
            message: message,
            level: "error",
          })
        );
      } else {
        console.error("Sign Up Failed:", error.message);
      }
    } finally {
      setIsSubmitting(false);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        userType: "",
        subscriptionType: "",
        password: "",
        entityName: "",
      });
    }
  };

  const isFormValid =
    formData.firstName &&
    formData.lastName &&
    formData.email &&
    formData.phone &&
    formData.subscriptionType &&
    ((formData.userType == UserType.BUSINESS && formData.entityName) ||
      formData.userType == UserType.INDIVIDUAL) &&
    formData.password.length >= 8;

  const handleLoginClick = () => {
    navigate("../");
  };

  const getFilteredSubscriptionOptions = () => {
    if (formData.userType === UserType.INDIVIDUAL) {
      return subscriptionOptions.filter((option) =>
        ["Starter", "Premium"].includes(option.value)
      );
    } else if (formData.userType === UserType.BUSINESS) {
      return subscriptionOptions.filter((option) =>
        ["Enterprise", "Team", "Starter"].includes(option.value)
      );
    }
    return [];
  };

  return (
    <Boarding
      buttonLabel="Login"
      paragraphContent="Already have an account?"
      onButtonClick={handleLoginClick}
      cardLabel="Seconds to sign up!"
    >
      <div className={styles.upperPart}>
        <div className={styles.inputContainer}>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              First Name
            </label>
            <CustomTextField
              placeholder="Enter your first name"
              width="100%"
              iconSrc={nameicon}
              value={formData.firstName}
              onChange={handleChange}
              name="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName}
              variant="outlined"
            />
          </div>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Last Name
            </label>
            <CustomTextField
              placeholder="Enter your last name"
              width="100%"
              iconSrc={nameicon}
              value={formData.lastName}
              onChange={handleChange}
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName}
              variant="outlined"
            />
          </div>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              E-mail
            </label>
            <CustomTextField
              placeholder="default@example.com"
              width="100%"
              iconSrc={emailicon}
              value={formData.email}
              onChange={handleChange}
              name="email"
              error={!!errors.email}
              helperText={errors.email}
              variant="outlined"
            />
          </div>
        </div>
        <div className={styles.signupElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Mobile Number
          </label>
          <CustomTextField
            placeholder="+12345678910"
            iconSrc={mobicon}
            width="100%"
            value={formData.phone}
            onChange={handleChange}
            name="phone"
            error={!!errors.phone}
            helperText={errors.phone}
            variant="outlined"
          />
        </div>
        <div className={styles.signupElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            User Type
          </label>
          <CustomSelect
            value={formData.userType}
            onChange={(e) => handleSelectChange(e, "userType")}
            options={accountTypes}
            displayEmpty
            className={styles.menu}
          />
        </div>
        {formData.userType == UserType.BUSINESS && (
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Business Name
            </label>
            <CustomTextField
              placeholder="GISimplify LLC"
              width="100%"
              iconSrc={emailicon}
              value={formData.entityName}
              onChange={handleChange}
              name="entityName"
              error={!!errors.entityName}
              helperText={errors.entityName}
              variant="outlined"
            />
          </div>
        )}
        <div className={styles.signupElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Subscription Type
          </label>
          <CustomSelect
            value={formData.subscriptionType}
            onChange={(e) => handleSelectChange(e, "subscriptionType")}
            options={getFilteredSubscriptionOptions()}
            displayEmpty
            className={styles.menu}
          />
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Password
            </label>
            <CustomPasswordField
              placeholder="At least 8 characters"
              iconSrc={passicon}
              value={formData.password}
              width="100%"
              onChange={handleChange}
              name="password"
              error={!!errors.password}
              helperText={errors.password}
            />
          </div>
        </div>
        <Button
          label="Signup"
          sx={{
            width: "100%",
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.light,
          }}
          disabled={!isFormValid || isSubmitting}
          onClick={handleSignUp}
        />
        <p style={{ fontWeight: 600, fontSize: "20px" }}>
          Already have an account?{" "}
          <a
            href="../"
            className={styles.under}
            style={{ color: theme.palette.primary.light }}
          >
            Login
          </a>
        </p>
      </div>
      <hr
        color={theme.palette.secondary.light}
        style={{ border: "0", borderTop: "1px solid" }}
      />
      <div className={styles.lowerPart}>
        <p style={{ fontSize: "16px" }}>
          {" "}
          By proceeding, you agree to the{" "}
          <span style={{ color: theme.palette.primary.light }}>
            Terms of Service
          </span>{" "}
          and{" "}
          <span style={{ color: theme.palette.primary.light }}>
            Privacy Policy
          </span>
        </p>
      </div>
    </Boarding>
  );
};

export default SignUp;
