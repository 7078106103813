import { Header } from "components/header";
import { SidePanel } from "components/side-panel";
import { Container } from "components/container";
import { IProps } from "./types/main";
import styles from "./assets/scss/main.module.scss";

const Main = ({ children, header, action, welcomeText }: IProps) => {
  return (
    <>
      <Header />
      <div className={styles.bodyContainer}>
        <SidePanel />
        <Container
          headerIcon={header.icon}
          headerLabel={header?.title}
          buttonLabel={action?.title}
          onButtonClick={action?.clickHandler}
          welcomeText={welcomeText}
        >
          {children}
        </Container>
      </div>
    </>
  );
};

export default Main;
