import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { CardProps } from "@mui/material";
import { CustomTextFieldProps } from "./types/TextField";

const CustomTextField: React.FC<CustomTextFieldProps> = (props) => {
  const {
    placeholder,
    value,
    onChange,
    type = "text",
    variant = "outlined",
    fullWidth = true,
    error = false,
    helperText,
    width = "100%",
    height,
    iconSrc,
    iconAlt = "icon",
    name,
    className,
    ...cardProps
  } = props;

  return (
    <div className={className} style={{ width: width }} {...cardProps}>
      <TextField
        id="outlined-basic"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        variant={variant}
        fullWidth={fullWidth}
        error={error}
        style={{
          width: width,
          height,
          marginBottom: "10px",
          fontFamily: "'Cairo', sans-serif",
        }}
        InputProps={{
          startAdornment: iconSrc && (
            <InputAdornment position="start">
              <img
                src={iconSrc}
                alt={iconAlt}
                style={{ width: "20px", height: "20px" }}
              />
            </InputAdornment>
          ),
          style: {
            fontFamily: "'Cairo', sans-serif",
            fontSize: "1rem",
          },
        }}
        name={name}
        sx={{
          "& .MuiOutlinedInput-root": {
            fontFamily: "'Cairo', sans-serif",
          },
          "& .MuiOutlinedInput-input": {
            fontFamily: "'Cairo', sans-serif",
            textTransform: "none",
            fontSize: "1rem",
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "inherit",
            "caret-color": "inherit",
          },
        }}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default CustomTextField;
