import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./assets/scss/success.module.scss";
import CustomCard from "components/mui/card/card";
import { Button } from "components/mui/button";
//logo
import person from "./assets/imgs/image 1.svg";
import note from "./assets/imgs/Vector.svg";
import { useTheme } from "@mui/material";
import { Boarding } from "layouts/boarding";

const Success = () => {
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    navigate("../home");
  };

  const handleSignupClick = () => {
    navigate("../signup/");
  };

  return (
    <Boarding paragraphContent="Don’t Have an account?">
      <img src={person} alt="Person" />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "20px",
        }}
      >
        <img
          src={note}
          alt="Note Icon"
          style={{
            width: "20px",
            height: "20px",
          }}
        />
        <p
          style={{
            fontSize: "14px",
            margin: 0,
            color: theme.custom.font.light,
          }}
        >
          Your password has been changed successfully. You can access the site.
        </p>
      </div>
      <Button
        label="Home Page"
        sx={{
          width: "100%",
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.light,
        }}
        onClick={handleLogin}
      />
    </Boarding>
  );
};

export default Success;
