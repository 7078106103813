import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardProps } from "@mui/material/Card";
import { IProps } from "./types/card";

const CustomCard = ({ label, children, sx, ...props }: IProps & CardProps) => {
  return (
    <Card sx={{ maxWidth: 600, ...sx }} {...props}>
      <CardContent>
        <h3 style={{ margin: "0px" }}>{label}</h3>
        {children}
      </CardContent>
    </Card>
  );
};

export default CustomCard;
