import React from "react";
import {
  Drawer,
  List,
  ListItem,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as HomeIcon } from "./assets/imgs/home.svg";
import { ReactComponent as Time } from "./assets/imgs/time.svg";
import { ReactComponent as Recent } from "./assets/imgs/recent.svg";
import { ReactComponent as Report } from "./assets/imgs/report.svg";
import { ReactComponent as Favorites } from "./assets/imgs/hugeicons_favourite.svg";
import { ReactComponent as Setting } from "./assets/imgs/setting.svg";
import { ReactComponent as Projects } from "./assets/imgs/Vector.svg";
import { ReactComponent as Logout } from "./assets/imgs/signout.svg";
import { ReactComponent as Plus } from "./assets/imgs/ic_round-plus.svg";
import { ReactComponent as Crown } from "./assets/imgs/crown_6941697 1.svg";
import { ReactComponent as Client } from "./assets/imgs/Clip path group.svg";
import { ReactComponent as Invoice } from "./assets/imgs/hugeicons_invoice-02.svg";

import styles from "./assets/scss/side-panel.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation

interface MenuItem {
  label: string;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
  path?: string; // Add a path property for navigation
}

const SidePanel: React.FC = () => {
  const theme = useTheme();
  const app = useSelector((state: any) => state.app?.allData || {});
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems: MenuItem[] = [
    { label: `${app.entity_name}` },
    { label: "Home", iconBefore: <HomeIcon />, path: "/home/" },
    { label: "Time Tracking", iconBefore: <Time />, path: "/time-tracking/" },
    {
      label: "Clients",
      iconBefore: <Client />,
      iconAfter: <Crown />,
      path: "/clients/",
    },
    {
      label: "Projects",
      iconBefore: <Projects />,
      iconAfter: <Plus />,
      path: "/projects/",
    },
    { label: "Recent", iconBefore: <Recent />, path: "/recent/" },
    { label: "Report", iconBefore: <Report />, path: "/report/" },
    {
      label: "Create an invoice",
      iconBefore: <Invoice />,
      iconAfter: <Crown />,
      path: "/create-invoice/",
    },
    { label: "Favorites", iconBefore: <Favorites />, path: "/favorites/" },
    { label: "Setting", iconBefore: <Setting />, path: "/setting/" },
    { label: "Logout", iconBefore: <Logout />, path: "/logout/" },
  ];

  const handleClick = (path: string) => {
    navigate(path);
  };

  return (
    <div
      className={styles.drawer}
      style={{ borderRight: `1px ${theme.custom.neutralColors.color2} solid` }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            key={index}
            component="li"
            sx={{
              padding: 0,
              marginTop: item.path ? "3px" : "25px",
              marginBottom: item.path ? 0 : "20px",
            }}
          >
            <Button
              onClick={() => handleClick(item.path || "#")}
              fullWidth
              style={{
                textAlign: "left",
                justifyContent: "flex-start",
                backgroundColor:
                  location.pathname === item.path
                    ? theme.custom.neutralColors.color13
                    : "transparent",
              }}
            >
              {item.label === `${app.entity_name}` ? (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    style={{
                      width: "30px",
                      height: "30px",
                      backgroundColor: theme.palette.secondary.main,
                      marginRight: "10px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        fontFamily: "Cairo",
                        color: theme.custom.neutralColors.color11,
                        textAlign: "center",
                      }}
                    >
                      {app.entity_name[0]}
                    </Typography>
                  </div>
                  <Typography
                    style={{
                      color: theme.custom.font.black,
                      fontSize: "12px",
                      fontFamily: "Cairo",
                      flex: 1,
                      textAlign: "left",
                    }}
                  >
                    {item.label}
                  </Typography>
                </div>
              ) : (
                <>
                  {item.iconBefore && (
                    <span style={{ marginRight: "8px" }}>
                      {item.iconBefore}
                    </span>
                  )}
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontFamily: "Cairo",
                      flex: 1,
                      textAlign: "left",
                      color: theme.custom.font.black,
                    }}
                  >
                    {item.label}
                  </Typography>
                  {item.iconAfter && (
                    <span style={{ marginLeft: "8px" }}>{item.iconAfter}</span>
                  )}
                </>
              )}
            </Button>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SidePanel;
