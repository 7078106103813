import { configureStore } from "@reduxjs/toolkit";

import uiSlice from "./slices/ui-slice";
import userSlice from "./slices/user-slice";
import appSlice from "./slices/app";
const store = configureStore({
  reducer: {
    ui: uiSlice,
    user: userSlice,
    app: appSlice,
  },
});

export default store;
