import { Card, useTheme } from "@mui/material";
import styles from "./assets/scss/container.module.scss";
import { Button } from "components/mui/button";
import { ContainerProps } from "./types/container";

const Container = ({
  children,
  headerIcon,
  headerLabel = "Container Label",
  buttonLabel,
  onButtonClick,
  welcomeText,
}: ContainerProps) => {
  const theme = useTheme();

  return (
    <div className={styles.contentContainer}>
      <div
        className={styles.gap}
        style={{
          color: theme.palette.secondary.dark,
          flex: welcomeText ? 2 : 1,
        }}
      >
        {welcomeText}
      </div>
      <Card className={styles.content} sx={{ flex: welcomeText ? 10 : 11 }}>
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.custom.neutralColors.color11,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "10px 20px",
            borderRadius: "16px 16px 0px 0px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {headerIcon && (
              <img
                src={headerIcon}
                alt="Header Icon"
                style={{ width: "24px", height: "24px", marginRight: "10px" }}
              />
            )}
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              {headerLabel}
            </span>
          </div>

          {buttonLabel && (
            <Button
              label={buttonLabel}
              sx={{
                color: theme.custom.neutralColors.color11,
                backgroundColor: theme.palette.primary.light,
                width: "auto",
              }}
              onClick={onButtonClick}
            />
          )}
        </div>

        <div
          style={{
            backgroundColor: theme.custom.neutralColors.color11,
            flex: 1,
            padding: "20px",
            overflowY: "auto",
            maxHeight: "75vh",
          }}
        >
          {children}
        </div>
      </Card>
    </div>
  );
};

export default Container;
