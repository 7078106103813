import React from "react";

interface PasswordStrengthIndicatorProps {
  strength: number;
}

const PasswordStrengthIndicator: React.FC<PasswordStrengthIndicatorProps> = ({
  strength,
}) => {
  return (
    <div style={{ display: "flex", gap: "4px", marginTop: "10px" }}>
      {[1, 2, 3, 4].map((index) => (
        <div
          key={index}
          style={{
            flex: 1,
            height: "8px",
            backgroundColor:
              index <= strength ? getStrengthColor(strength) : "#E0E0E0",
            borderRadius: "2px",
          }}
        />
      ))}
    </div>
  );
};

const getStrengthColor = (strength: number) => {
  if (strength <= 1) return "#FF0000";
  if (strength === 2) return "#FFA500";
  if (strength === 3) return "#FFFF00";
  return "#00FF00";
};

export default PasswordStrengthIndicator;
