import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "ui",
  initialState: {
    loading: false,
    notification: {
      level: "",
      message: "",
    },
    isMuted: false,
    theme: "light",
  },
  reducers: {
    updateNotification(state, action) {
      state.notification.level = action.payload.level;
      state.notification.message = action.payload.message;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setMute(state, action) {
      state.isMuted = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;

export default uiSlice.reducer;
