import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormHelperText from "@mui/material/FormHelperText";
import { CustomPasswordFieldProps } from "./types/PassField";

const CustomPasswordField: React.FC<CustomPasswordFieldProps> = (props) => {
  const {
    placeholder,
    value,
    onChange,
    width = "100%",
    helperText,
    error = false,
    iconSrc,
    name,
    iconAlt = "icon",
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{
        width: width,
        marginBottom: "10px",
      }}
      variant="outlined"
      error={error}
    >
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        startAdornment={
          iconSrc && (
            <InputAdornment position="start">
              <img
                src={iconSrc}
                alt={iconAlt}
                style={{ width: "24px", height: "24px" }}
              />
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={
                showPassword ? "hide the password" : "display the password"
              }
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "inherit",
            "caret-color": "inherit",
          },
        }}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomPasswordField;
