import { Typography, useTheme } from "@mui/material";
import { Main } from "layouts/main";
import projects from "./assets/imgs/Vector.svg";
import styles from "./assets/scss/projects.module.scss";
import plus from "./assets/imgs/plus.svg";
import { Button } from "components/mui/button";

const Projects = () => {
  const theme = useTheme();

  const handleButtonClick = () => {
    alert("Button clicked!");
  };

  return (
    <Main
      action={{ title: "Create Project", clickHandler: () => {} }}
      header={{ icon: projects, title: "projects" }}
    >
      <Button
        label={<img src={plus} alt="plus" />}
        sx={{
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.light,
        }}
        className={styles.btn}
      />
      <Typography
        variant="body2"
        className={styles.cardText}
        style={{
          color: theme.custom.font.light,
          fontSize: "14px",
          fontWeight: "400",
          textAlign: "center",
          marginTop: "8px",
        }}
      >
        There are no projects yet, you can{" "}
        <a
          style={{
            color: theme.palette.primary.light,
            fontSize: "14px",
            fontWeight: "400",
          }}
          href="#"
        >
          start now.
        </a>{" "}
      </Typography>
    </Main>
  );
};

export default Projects;
