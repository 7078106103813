import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    allData: JSON.parse(localStorage.getItem("appData") || "{}") || {
      token: "",
      memberId: "",
      entityId: "",
      email: "",
      entity_name: "",
      role: "",
      last_name: "",
      first_name: "",
    },
  },
  reducers: {
    setAppData(state, action) {
      const {
        token = "",
        memberId = "",
        entityId = "",
        email = "",
        entity_name = "",
        role = "",
        first_name = "",
        last_name = "",
      } = action.payload.data || {};
      const updatedData = {
        token,
        memberId,
        entityId,
        email,
        entity_name,
        role,
        first_name,
        last_name,
      };
      state.allData = updatedData;

      localStorage.setItem("appData", JSON.stringify(updatedData));
    },
  },
});

export const appActions = appSlice.actions;

export default appSlice.reducer;
