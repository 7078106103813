import React, { useState } from "react";
import styles from "./assets/scss/login.module.scss";
import CustomTextField from "components/mui/text-field/text-field";
import CustomPasswordField from "components/mui/pass-text-field/pass-text-field";
import { Button } from "components/mui/button";
import axios from "axios";
import EmailIcon from "./assets/imgs/mage_email-notification.svg";
import passicon from "./assets/imgs/Group.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui/ui-slice";
import { appActions } from "store/slices/app";
import { useTheme } from "@mui/material";
import { Boarding } from "layouts/boarding";

const Login = () => {
  const theme = useTheme();
  const [inputValue, setInputValue] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: "",
    password: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const passwordValue = event.target.value;
    setPassword(passwordValue);

    const passwordValidation = validatePassword(passwordValue);
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      password: passwordValidation,
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    if (!event.target.value) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: "Email is required.",
      }));
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(event.target.value)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: "Enter a valid email.",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        email: "",
      }));
    }
  };

  const apiURL = process.env.REACT_APP_API_URL || "";

  const validateInputs = () => {
    const errors: { email: string; password: string } = {
      email: validationErrors.email,
      password: validationErrors.password,
    };

    return !errors.email && !errors.password;
  };

  const handleLogin = async () => {
    if (!validateInputs()) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(`${apiURL}/login/`, {
        email: inputValue,
        password: password,
      });

      dispatch(appActions.setAppData(response.data));

      console.log("Login Successful:", response.data);
      dispatch(
        uiActions.updateNotification({
          message: response.data.message || "Login successful",
          level: "success",
        })
      );

      const temp = response.data.data.is_temp;
      if (temp === true) {
        navigate("../Changepassword/");
      }

      const status = response.data.data.status;
      if (status === 2) {
        navigate("../verify/");
      } else if (status === 1 && temp === false) {
        navigate("../home/");
      }
    } catch (error: any) {
      const message = error.response?.data?.message || "Unknown error";
      console.error("Login Failed:", message);

      dispatch(
        uiActions.updateNotification({
          message,
          level: "error",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const isButtonDisabled = isSubmitting;

  const validatePassword = (password: string) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    // if (!/[A-Z]/.test(password)) {
    //   return "Password must contain at least one uppercase letter.";
    // }
    if (!/[0-9]/.test(password)) {
      return "Password must contain at least one number.";
    }
    // if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
    //   return "Password must contain at least one special character.";

    return "";
  };

  return (
    <Boarding
      paragraphContent="Don’t Have an account?"
      cardLabel="Seconds to Login!"
    >
      <div className={styles.upperPart}>
        <div className={styles.inputContainer}>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              E-mail
            </label>
            <CustomTextField
              placeholder="default@example.com"
              width="100%"
              value={inputValue}
              onChange={handleChange}
              error={!!validationErrors.email}
              helperText={validationErrors.email}
              variant="outlined"
              iconSrc={EmailIcon}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.signupElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Password
            </label>
            <CustomPasswordField
              iconSrc={passicon}
              placeholder="At least 8 characters"
              value={password}
              width="100%"
              onChange={handlePasswordChange}
              error={!!validationErrors.password}
              helperText={validationErrors.password}
            />
          </div>
          <a
            href="/forgetpassword/"
            className={styles.forgotPasswordLink}
            style={{ color: theme.custom.font.light, fontSize: "16px" }}
          >
            Forgot Password?
          </a>
        </div>
        <Button
          label="Login"
          sx={{
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.light,
            width: "100%",
          }}
          disabled={isButtonDisabled}
          onClick={handleLogin}
        />
        <p style={{ fontWeight: 600, fontSize: "20px", margin: "1vh" }}>
          Don’t Have an account?{" "}
          <a
            href="/SignUp/"
            className={styles.under}
            style={{ color: theme.palette.primary.light }}
          >
            Signup
          </a>
        </p>
      </div>
      <hr
        color={theme.palette.secondary.light}
        style={{ border: "0", borderTop: "1px solid" }}
      />
      <div className={styles.lowerPart}>
        <p style={{ fontSize: "16px", margin: "1vh" }}>
          By proceeding, you agree to the{" "}
          <span style={{ color: theme.palette.primary.light }}>
            Terms of Service{" "}
          </span>
          and{" "}
          <span style={{ color: theme.palette.primary.light }}>
            Privacy Policy
          </span>
        </p>
      </div>
    </Boarding>
  );
};

export default Login;
