import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "store/interface/stateInterfaces";

const initState: IUser = {
  id: "",
  token: "",
};

const userSlice = createSlice({
  name: "user",
  initialState: initState,
  reducers: {
    updateUserData(state, action) {
      state.id = action.payload.id;
      state.token = action.payload.token;
    },
  },
});

export const { updateUserData } = userSlice.actions;

export default userSlice.reducer;
