// components
import { Button } from "components/mui/button";
// layouts
import { Main } from "layouts/main";
// mui
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Card,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
// assets
import Help from "./assets/imgs/ooui_help-rtl.svg";
import Rocket from "./assets/imgs/mynaui_rocket.svg";
import home from "./assets/imgs/Vector.svg";
import plus from "./assets/imgs/plus.svg";
import dots from "./assets/imgs/lsicon_more-filled.svg";
import styles from "./assets/scss/home.module.scss";
// redux
import { useSelector } from "react-redux";
// utils
import { toPascal } from "utils/text";

const Home = () => {
  const theme = useTheme();
  const app = useSelector((state: any) => state.app?.allData || {});

  return (
    <Main
      // action={{ title: "lilo", clickHandler: () => {} }}
      header={{ icon: home, title: "Home" }}
      welcomeText={`Hi, ${toPascal(app.first_name)}`}
    >
      <div className={styles.cardContainer}>
        <Card
          className={styles.card}
          elevation={3}
          sx={{ border: `1px solid ${theme.custom.neutralColors.color2}` }}
        >
          <div className={styles.cardHeader}>
            <Typography variant="h6" className={styles.Text}>
              Recent Projects
            </Typography>
            {/* <Button
              label={<img src={dots} alt="dots" />}
              backgroundColor={theme.custom.neutralColors.color11}
              color={theme.custom.font.dark}
              className={styles.btn2}
            /> */}
          </div>

          {/* Centered Plus Button and Text */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100% - 60px)", // Adjust height to account for header
            }}
          >
            <Button
              label={<img src={plus} alt="plus" />}
              sx={{
                color: theme.custom.font.dark,
                backgroundColor: theme.custom.neutralColors.color11,
                border: `1px solid ${theme.custom.neutralColors.color4} !important`,
                boxShadow: `0 4px 8px ${theme.custom.neutralColors.color3}`,
              }}
              className={styles.btn}
            />
            <Typography
              variant="body2"
              className={styles.cardText}
              style={{
                color: theme.custom.font.light,
                fontSize: "25px",
                fontWeight: "400",
                textAlign: "center",
                marginTop: "8px",
              }}
            >
              There are no projects yet, you can{" "}
              <a
                style={{
                  color: theme.palette.primary.light,
                  fontSize: "25px",
                  fontWeight: "400",
                }}
                href="#"
              >
                start now.
              </a>{" "}
            </Typography>
          </div>
        </Card>

        {/* Right Section */}
        <div className={styles.rightSection}>
          <h3 style={{ textAlign: "left" }}>Learn & get inspired</h3>
          <Grid
            container
            direction="column"
            spacing={1}
            sx={{ paddingLeft: "15px" }}
          >
            <Grid item>
              <Grid container alignItems="left" spacing={1}>
                <Grid item>
                  <div
                    className={styles.iconContainer}
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <IconButton color="primary" className={styles.iconButton}>
                      <img
                        src={Rocket}
                        alt="Rocket Icon"
                        className={styles.icon}
                      />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Getting started
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Learn how ticktrack.com works
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <div
                    className={styles.iconContainer}
                    style={{
                      backgroundColor: theme.custom.neutralColors.color12,
                    }}
                  >
                    <IconButton color="primary" className={styles.iconButton}>
                      <img src={Help} alt="Help Icon" className={styles.icon} />
                    </IconButton>
                  </div>
                </Grid>
                <Grid item>
                  <Typography variant="body1" style={{ textAlign: "left" }}>
                    Help center
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    Learn and get support
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>

      {/* Table Section */}
      <div className={styles.tableSection}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" className={styles.sectionTitle}>
              Your Tasks
            </Typography>
          </Grid>
          <Grid item>
            <Button
              label="Show all"
              sx={{
                color: theme.palette.primary.light,
                width: "100%",
              }}
              className="btn1"
            />
          </Grid>
        </Grid>

        <TableContainer className={styles.table}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Task Name{" "}
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Creation Date
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Expiration Date
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  TRACKER Type
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Task type{" "}
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Duration{" "}
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Task Status
                </TableCell>
                <TableCell style={{ color: theme.custom.font.light }}>
                  Priority{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody></TableBody>
          </Table>
        </TableContainer>
      </div>
    </Main>
  );
};

export default Home;
